import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="offices"
export default class extends Controller {
  connect() {
  }

  updateUrl(event) {
    const url = event.currentTarget.getAttribute("href");
    window.history.pushState({}, "", url );
  }
}
